$ = jQuery;

/*
* Evento de clique nas redes sociais do footer
*/
$('.jc-socia-media-footer a').on('click', function() {
  dataLayer.push({
    'event': 'ga_event',
    'eventCategory': 'Footer',
    'eventAction': 'Clique - Rede Social',
    'eventLabel': $(this).attr('data-social-type')
  });

  gtag('event', 'Footer', {
    'eventAction': 'Clique - Rede Social',
    'eventLabel': $(this).attr('data-social-type')
  });
});
